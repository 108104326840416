import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';
import ImagePreload, { prepareImagePreload } from '@nubank/www-latam-commons/components/ImagePreload/ImagePreload';
import ShortForm from 'patterns/ShortForm/ShortForm';
import ProductPageOpenFormSection from 'patterns/ProductPageOpenFormSection/ProductPageOpenFormSection';
import RegistrationUnavailableLanguage from 'patterns/RegistrationUnavailableLanguage/RegistrationUnavailableLanguage';
import useOpenFormVisibility from 'utils/hooks/useOpenFormVisibility/useOpenFormVisibility';
import { DEFAULT_LOCALE } from 'utils/i18nUtils';
import { CREDIT_CARD } from 'utils/prospectTypes';

function SectionFormVariant() {
  const { locale } = useIntl();
  const [ref] = useOpenFormVisibility();
  const imageSrcSet = generateDPRsForCMSImage([{
    imageMobile: { url: 'https://nubank.com.br/images-cms/1713550066-hero-mobile-cartao-1.jpg' },
    imageTablet: { url: 'https://nubank.com.br/images-cms/1713550072-hero-tablet-cartao-1.jpg' },
    imageDesktop: { url: 'https://nubank.com.br/images-cms/1713550057-hero-desk-cartao-1.jpg' },
  }]);

  return (
    <>
      <ImagePreload srcSet={prepareImagePreload(imageSrcSet)} />
      <ProductPageOpenFormSection
        ref={ref}
        backgroundImage={imageSrcSet}
        backgroundPosition="center"
        backgroundSize="cover"
      >
        <ProductPageOpenFormSection.Container
          gridTemplateRows={{ xs: 'auto 18rem 1rem 20rem', md: 'auto 1fr', lg: '1fr' }}
          gridRowGap={{ xs: '2x', md: '6x' }}
          overflow="hidden"
        >
          <ProductPageOpenFormSection.Heading
            gridColumn={{ xs: '1 / span 4', md: '2 / span 4', lg: '1 / span 5' }}
          >
            <Typography
              variant="heading4"
              tag="h1"
              textAlign={{ xs: 'center', md: 'left' }}
              intlKey="cms.cardPage.cardHeroTitle"
              marginBottom={{ xs: '2x', md: '6x' }}
            />
            <Typography
              variant="heading2"
              tag="h2"
              textAlign={{ xs: 'center', md: 'left' }}
              intlKey="cms.cardPage.cardHeroCtaDescription"
            />
          </ProductPageOpenFormSection.Heading>
          <ProductPageOpenFormSection.FormBlock
            gridColumn={{ xs: '1 / span 4', md: '4 / span 3', lg: '9 / span 4' }}
            gridRow={{ xs: '3 / span 2', md: '2', lg: '1' }}
          >
            {locale === DEFAULT_LOCALE ? (

              <ShortForm
                prospectTypeKey={CREDIT_CARD}
                formTitle="SHORT_FORM.CREDIT_CARD.FORM_TITLE"
              />

            ) : (
              <RegistrationUnavailableLanguage isOpenForm isShortForm />
            )}
          </ProductPageOpenFormSection.FormBlock>
        </ProductPageOpenFormSection.Container>
      </ProductPageOpenFormSection>
    </>
  );
}

export default React.memo(SectionFormVariant);
